import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _75f7728c = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _1c9c7c44 = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _adf27484 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _192b48dc = () => interopDefault(import('../pages/meus-dados.vue' /* webpackChunkName: "pages/meus-dados" */))
const _eaf28d58 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _60bff740 = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _75460ad2 = () => interopDefault(import('../pages/test-drive.vue' /* webpackChunkName: "pages/test-drive" */))
const _77b8a81c = () => interopDefault(import('../pages/vencedores.vue' /* webpackChunkName: "pages/vencedores" */))
const _2f89ac28 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _d0dea59e = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _e443c55a = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _190d4f84 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _57aeaf7c = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _45edd973 = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _71d74fb3 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _5ebf482c = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _53a6e9fe = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _5e4bc46b = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _856c997c = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _5175d831 = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _3ee7b497 = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _a18b9a22 = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _72bdfba8 = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _bb4bb476 = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _f37f3398 = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _4e79b02a = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _9220966e = () => interopDefault(import('../pages/admin/tests-drive/index.vue' /* webpackChunkName: "pages/admin/tests-drive/index" */))
const _1ec57d92 = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _90ddd436 = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _11a5d349 = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _72fa3373 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _f9328b02 = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _3a68cbdc = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _6ab23e6c = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _dfcbe85c = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _d3ff615c = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _a132f8ec = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _c0fcc0aa = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _1e721d2a = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _17191fdd = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _6980ab0a = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _6245f179 = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _140085ab = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _481a7529 = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _8334cb38 = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _ce341754 = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _20d12f7a = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _0ce9c2b8 = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _06649890 = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _a40cc6ca = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _02cf9e2a = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _07639a4e = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _7de10acb = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _38a19f94 = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _0350d56d = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _3f465571 = () => interopDefault(import('../pages/admin/tests-drive/_id.vue' /* webpackChunkName: "pages/admin/tests-drive/_id" */))
const _7bfde7d1 = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _6b1aa02e = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _33cea17a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _75f7728c,
    name: "admin"
  }, {
    path: "/cupom",
    component: _1c9c7c44,
    name: "cupom"
  }, {
    path: "/faq",
    component: _adf27484,
    name: "faq"
  }, {
    path: "/meus-dados",
    component: _192b48dc,
    name: "meus-dados"
  }, {
    path: "/perfil",
    component: _eaf28d58,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _60bff740,
    name: "regulamentos"
  }, {
    path: "/test-drive",
    component: _75460ad2,
    name: "test-drive"
  }, {
    path: "/vencedores",
    component: _77b8a81c,
    name: "vencedores"
  }, {
    path: "/admin/actions",
    component: _2f89ac28,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _d0dea59e,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _e443c55a,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _190d4f84,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _57aeaf7c,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _45edd973,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _71d74fb3,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _5ebf482c,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _53a6e9fe,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _5e4bc46b,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _856c997c,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _5175d831,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _3ee7b497,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _a18b9a22,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _72bdfba8,
    name: "admin-prizes"
  }, {
    path: "/admin/registrations",
    component: _bb4bb476,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _f37f3398,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _4e79b02a,
    name: "admin-statistics"
  }, {
    path: "/admin/tests-drive",
    component: _9220966e,
    name: "admin-tests-drive"
  }, {
    path: "/admin/tickets",
    component: _1ec57d92,
    name: "admin-tickets"
  }, {
    path: "/admin/tokens",
    component: _90ddd436,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _11a5d349,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _72fa3373,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _f9328b02,
    name: "admin-winners"
  }, {
    path: "/forgot",
    component: _3a68cbdc,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _6ab23e6c,
    name: "auth-login"
  }, {
    path: "/register",
    component: _dfcbe85c,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _d3ff615c,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _a132f8ec,
    name: "admin-auth-login"
  }, {
    path: "/admin/dispatches/create",
    component: _c0fcc0aa,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _1e721d2a,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _17191fdd,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _6980ab0a,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _6245f179,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _140085ab,
    name: "admin-users-create"
  }, {
    path: "/admin/reset/:token?",
    component: _481a7529,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _8334cb38,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _ce341754,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _20d12f7a,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _0ce9c2b8,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _06649890,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _a40cc6ca,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _02cf9e2a,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _07639a4e,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _7de10acb,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _38a19f94,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _0350d56d,
    name: "admin-registrations-id"
  }, {
    path: "/admin/tests-drive/:id?",
    component: _3f465571,
    name: "admin-tests-drive-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _7bfde7d1,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _6b1aa02e,
    name: "auth-reset-token"
  }, {
    path: "/",
    component: _33cea17a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
