
import merge from '~lodash.mergewith'
import { init } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F28be28671545c2cb8cea7e8f472f132b@o527168.ingest.us.sentry.io\u002F4507016946515968",
    environment:"production",
    ignoreErrors:["top.GLOBALS","originalCreateNotification","canvas.contentDocument","MyApp_RemoveAllHighlights","http:\u002F\u002Ftt.epicplay.com","Can't find variable: ZiteReader","jigsaw is not defined","ComboSearch is not defined","http:\u002F\u002Floading.retry.widdit.com\u002F","atomicFindClose","https:\u002F\u002Fcdn.cookielaw.org\u002Fscripttemplates\u002F202209.1.0\u002FotBannerSdk.js","onetrust-banner-sdk","fb_xd_fragment","bmi_SafeAddOnload","EBCallBackMessageReceived","conduitPage","Script error.","_avast_submit","undefined is not an object (evaluating 't.Domain')","null is not an object (evaluating 'c.style')","ReCaptcha error: Failed to load script"],
    denyUrls:[new RegExp("pagead\\\u002Fjs", "i"),new RegExp("graph\\.facebook\\.com", "i"),new RegExp("connect\\.facebook\\.net\\\u002Fen_US\\\u002Fall\\.js", "i"),new RegExp("eatdifferent\\.com\\.woopra-ns\\.com", "i"),new RegExp("static\\.woopra\\.com\\\u002Fjs\\\u002Fwoopra\\.js", "i"),new RegExp("extensions\\\u002F", "i"),new RegExp("^chrome:\\\u002F\\\u002F", "i"),new RegExp("127\\.0\\.0\\.1:4001\\\u002Fisrunning", "i"),new RegExp("webappstoolbarba\\.texthelp\\.com\\\u002F", "i"),new RegExp("metrics\\.itunes\\.apple\\.com\\.edgesuite\\.net\\\u002F", "i")],
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
